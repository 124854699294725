<template>
  <div className="animated">
    <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>Data Table Total Agent
      </b-card-header>
      <b-card-body>
        <end-user></end-user>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import EndUser from './Table'

  export default {
    name: 'Agents',
    components: {
      EndUser
    }
  }
</script>
